/*@import url("https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400");*/
/* vietnamese */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Barlow Semi Condensed ExtraLight'), local('BarlowSemiCondensed-ExtraLight'), url("./shared/barlowsemicondensed.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Barlow Semi Condensed ExtraLight'), local('BarlowSemiCondensed-ExtraLight'), url("./shared/barlowsemicondensed.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Barlow Semi Condensed ExtraLight'), local('BarlowSemiCondensed-ExtraLight'), url("./shared/barlowsemicondensed.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Semi Condensed Light'), local('BarlowSemiCondensed-Light'), url("./shared/barlowsemicondensed-light.otf") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Semi Condensed Light'), local('BarlowSemiCondensed-Light'), url("./shared/barlowsemicondensed-light.otf") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face { 
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Semi Condensed Light'), local('BarlowSemiCondensed-Light'), url("./shared/barlowsemicondensed-light.otf") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
