
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 120px
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}
.loading-wheel{
  color: #0a0135;
  font-size: 4em;
  text-align: center;
  margin-top: 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.row-header{
    margin:0px auto;
    padding:0px auto;
}

.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height:400px;
}

/*.footer{
    background-color: rgb(245, 245, 245, 0.6);
    margin:0px auto;
    padding: 10px 0px 10px 0px;
    flex-shrink: 0;
}*/
.jumbotron {
    padding:70px 30px 70px 30px;
    margin:0px auto;
    background: #9575CD ;
    color:floralwhite;
}

address{
    font-size:80%;
    margin:0px;
    color:#0f0f0f;
}

.navbar-dark {
    background-color: #512DA8;
}
.bg-light{
  background-color: rgb(248,249,250,0.8)!important

}

.fill {
  flex: 1 0 auto;
  /*min-height: 100%;
  height: 80vh;*/

    
}
.row{
  min-height:10px;
}
.big-logo{
  font-size: 4em;
  margin-bottom:10%;
  color: #0a0135;
  font-family: 'Caviar Dreams'
}
.generate a{
  text-decoration: none;
  color: #0a0135;
  border-radius: 0;
}
.generate {
  font-size: 4em;
  margin-top:15%;
  color: #0a0135;
  /*font-family: 'Barlow Semi Condensed';
  font-weight: 200 !important;*/
}
.second-select {
  margin-top: 20px
}
.generate-under {
  font-size: 4em;
  margin-top: 15px;
  color: #0a0135;
  /*font-family: 'Barlow Semi Condensed';
  font-weight: 200;*/
}
.gen-button {
  border-radius: 0;
}
.generate a:hover {
  color: #c1c5fc
}
.drop {
  background-color: rgb(235, 235, 235);
  color: #0a0135;
  border-color:  rgb(235, 235, 235);
  border-radius: 0;
  -webkit-font-smooting: 'antialiased'
}
.canvas {
  height: 100%;
  /*font-family: 'Barlow Semi Condensed', sans-serif*/
}
#myCanvas {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 200;
}
.drop-button {
  margin-top: 0px;
  margin-bottom: 20px
}
#copy {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 300 !important
}
footer {
    background-color: rgb(245, 245, 245, 0.6);
    position: absolute;
    bottom: 0;
    width: 100%;
    /*height: 60px;*/
    line-height: 30px;
    display: block;
}
#loading {
  color: rgb(245, 245, 245);
}
.download {
  text-decoration: none;
  color: #6d6f74
}
